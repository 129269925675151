import React from "react";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../ui/Loading Spinner/LoadingSpinner";
import { selectSelectedTest } from "../../pages/TestData Selection/testsSlice";
import classes from "./ProgressDetails.module.css";
import failed from "../../ui/Assets/Icons/fail.png";
import blocked from "../../ui/Assets/Icons/blocked.png";
import success from "../../ui/Assets/Icons/check.png";
import {
  selectScenarioException,
  selectStatus,
} from "../../pages/Results Report Page/resultsSlice";

function ProgressDetails() {
  const selectedTest = useSelector(selectSelectedTest);

  const status = useSelector(selectStatus);
  const scenarioException = useSelector(selectScenarioException);

  function getProgress() {
    if (status === "Pending...") {
      return <LoadingSpinner />;
    } else if (status === "Success") {
      return <img src={success} alt="" style={{ width: "3rem" }} />;
    } else if (status === "Failed") {
      return <img src={failed} alt="" style={{ width: "3rem" }} />;
    } else if (status === "Blocked") {
      return <img src={blocked} alt="" style={{ width: "3rem" }} />;
    }
  }
  const actualProgress = getProgress();
  return (
    <div className={classes.progressDetails}>
      <div className={classes.progressDetailsTitle}>
        {selectedTest === undefined ? (
          <p></p>
        ) : (
          <p>{selectedTest.scenarioTitle}</p>
        )}
      </div>
      <div
        className={classes.progressDetailsStatus}
        data-cy="progress-details-status"
      >
        {/* Test completed successfully! */}
      </div>
      <div className={classes.progressDetailsResult}>
        Test description:
        {selectedTest === undefined ? (
          <p></p>
        ) : (
          <p>{selectedTest.scenarioDescription}</p>
        )}
      </div>
      <div className={classes.progressDetailsMessage}>
        Status:
        {selectedTest === undefined ? (
          <p></p>
        ) : (
          <div style={{ marginTop: "1rem" }} data-cy="status">
            {actualProgress}
          </div>
        )}
      </div>
      {scenarioException ? (
        <div className={classes.scenarioException}>{scenarioException}</div>
      ) : (
        <div className={classes.scenarioException}></div>
      )}
    </div>
  );
}

export default ProgressDetails;
