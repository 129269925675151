import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postWithAuth } from "../../utils/api";
import { logError } from "../../utils/utils";
import { errorHandler } from "../../components/Error Handler/errorHandler";

function findSystemId(data, environment, region, dispatch) {
  const matchingElement = data.find(
    (item) =>
      item.environment === environment &&
      item.region === region &&
      item.vehcileExists === true
  );

  if (matchingElement) {
    const { systemId } = matchingElement;
    dispatch(setSystemID(systemId));
  }
}

function findUrl(data, environment, region, dispatch) {
  const matchingElement = data.find(
    (item) =>
      item.environment === environment &&
      item.region === region &&
      item.vehcileExists === true
  );

  if (matchingElement) {
    const { url } = matchingElement;
    dispatch(setUrl(url));
  }
}

function vinCheck(data, environment, region, dispatch) {
  if (data.some((item) => item.VehcileExists === true)) {
    dispatch(setVehicleNotFound(false));
    dispatch(setVinAvailableInOtherEnvOrReg(true));
  } else {
    dispatch(setVehicleNotFound(true));
    dispatch(setVinAvailableInOtherEnvOrReg(false));
  }
  const matchingElement = data.find(
    (item) =>
      item.environment === environment &&
      item.region === region &&
      item.vehcileExists === true
  );

  if (matchingElement) {
    dispatch(setVinStagesAndEnvironmentsCheck(true));
  } else {
    dispatch(setVinStagesAndEnvironmentsCheck(false));
  }
}

const initialState = {
  vinStagesAndEnvironments: [],
  vinCheckArray: [],
  vinStagesAndEnvironmentsCheck: false,
  isLoadingDataVinCheck: false,
  systemID: "",
  url: "",
  vehicleNotFound: false,
  vinAvailableInOtherEnvOrReg: false,
};

// Slice
const vdsCheckSlice = createSlice({
  name: "vdsCheck",
  initialState,
  reducers: {
    setVinStagesAndEnvironments: (state, action) => {
      state.vinStagesAndEnvironments = action.payload;
    },
    setVinCheckArray: (state, action) => {
      state.vinCheckArray = action.payload;
    },
    setVinStagesAndEnvironmentsCheck: (state, action) => {
      state.vinStagesAndEnvironmentsCheck = action.payload;
    },
    setIsLoadingDataVinCheck: (state, action) => {
      state.isLoadingDataVinCheck = action.payload;
    },
    setSystemID: (state, action) => {
      state.systemID = action.payload;
    },
    setUrl: (state, action) => {
      state.url = action.payload;
    },
    setVehicleNotFound: (state, action) => {
      state.vehicleNotFound = action.payload;
    },
    setVinAvailableInOtherEnvOrReg: (state, action) => {
      state.vinAvailableInOtherEnvOrReg = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getVehicleStagesAndEnvironments.fulfilled,
      (state, action) => {
        // Add other functionalities & handle state updates upon fulfillment as needed
      }
    );
  },
});

// Selectors

export const selectVinStagesAndEnvironments = (state) =>
  state.vdsCheck.vinStagesAndEnvironments;
export const selectVinCheckArray = (state) => state.vdsCheck.vinCheckArray;
export const selectVinStagesAndEnvironmentsCheck = (state) =>
  state.vdsCheck.vinStagesAndEnvironmentsCheck;
export const selectIsLoadingDataVinCheck = (state) =>
  state.vdsCheck.isLoadingDataVinCheck;
export const selectSystemID = (state) => state.vdsCheck.systemID;
export const selectUrl = (state) => state.vdsCheck.url;
export const selectVehicleNotFound = (state) => state.vdsCheck.vehicleNotFound;
export const selectVinAvailableInOtherEnvOrReg = (state) =>
  state.vdsCheck.vinAvailableInOtherEnvOrReg;

// Actions

export const {
  setVinStagesAndEnvironments,
  setVinCheckArray,
  setVinStagesAndEnvironmentsCheck,
  setIsLoadingDataVinCheck,
  setSystemID,
  setUrl,
  setVehicleNotFound,
  setVinAvailableInOtherEnvOrReg,
} = vdsCheckSlice.actions;

//Getter

// Using Redux Middleware
export const getVehicleStagesAndEnvironments = createAsyncThunk(
  "vdsCheck/getVehicleStagesAndEnvironments",
  async ({ environment, region, vin }, { dispatch }) => {
    dispatch(setIsLoadingDataVinCheck(true));
    try {
      const response = await postWithAuth(
        "GETVEHICLESTAGESANDENVIRONMENTS",
        {},
        { environment, region, vin }
      );
      if (response !== null) {
        dispatch(setVinStagesAndEnvironments(response));
        dispatch(setVinCheckArray(response));
        findSystemId(response, environment, region, dispatch);
        findUrl(response, environment, region, dispatch);
        vinCheck(response, environment, region, dispatch);
      } else {
        console.log(response);
      }
    } catch (error) {
      logError(error);
      errorHandler(error, dispatch);
      if (error.code === "ERR_BAD_REQUEST") {
        dispatch(setVehicleNotFound(true));
      }
    } finally {
      dispatch(setIsLoadingDataVinCheck(false));
    }
  }
);
export default vdsCheckSlice.reducer;
