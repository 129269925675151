import React, { useEffect } from "react";
import classes from "./landingPageWithThumbnails.module.css";
import dpssPrev from "../../ui/Assets/Icons/biggerHeightDPSSNoText.jpg";
import backendPrev from "../../ui/Assets/Icons/biggerHeightDataNoText.jpg";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjects,
  selectIsFirstVisit,
  setIsFirstVisit,
} from "../Homepage/projectSlice";
import { getTests } from "../TestData Selection/testsSlice";
import { Typography } from "@mui/material";
import fileGenerator from "../../ui/Assets/Icons/fileGenerator.png";
// import certificateCreator from "../../ui/Assets/Icons/certificateCreator.png";
import vinSearch from "../../ui/Assets/Icons/vinSearch.png";
import vdsCheckIcon from "../../ui/Assets/Icons/vdsCheckIcon.png";
import pseudonymizer from "../../ui/Assets/Icons/pseudonymizer.png";
import certificateBundles from "../../ui/Assets/Icons/certificateBundles.png";
import transformers from "../../ui/Assets/Icons/transformers.png";
import Themesong from "#components/themesong/Themesong.jsx";

function LandingPageWithThumbnails() {
  const history = useHistory();
  const dispatch = useDispatch();

  const isFirstVisit = useSelector(selectIsFirstVisit);

  useEffect(() => {
    const hasBeenVisitedBefore = sessionStorage.getItem("hasBeenVisited");
    if (!hasBeenVisitedBefore) {
      sessionStorage.setItem("hasBeenVisited", "true");
      dispatch(setIsFirstVisit(true));
      window.location.reload();
      dispatch(getProjects());
      dispatch(getTests());
    } else {
      dispatch(getProjects());
      dispatch(getTests());
    }
  }, [dispatch, isFirstVisit]);

  function dpssHandler() {
    history.push("/welcome");
  }
  function backendHandler() {
    history.push("/backendData");
  }

  function searchVinByUuidHandler() {
    history.push("/vinByUuID");
  }

  function ccpGeneratorHandler() {
    history.push("/ccpGenerator");
  }

  // function sopViewerHandler() {
  //   history.push("/sopViewer");
  // }

  function pseudonymizerHandler() {
    history.push("/pseudonymizer");
  }

  // function vkmsCertificateCreatorHandler() {
  //   history.push("/vkmsCertificate");
  // }

  function certificateBundlesHandler() {
    history.push("/certificateBundles");
  }

  function vdsCheckHandler() {
    history.push("/vdsCheck");
  }
  function adminToolTraceTransformerHandler() {
    history.push("/adminToolTraceTransformer");
  }
  return (
    <>
      {" "}
      <div
        className={classes.flexContainerHeader}
        style={{
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          overflow: "hidden",
          backgroundColor: "var(--secondary",
        }}
      >
        <div
          style={{
            width: "100%",
            position: "relative",
            height: "1.5rem",
          }}
        >
          <div style={{ position: "absolute", left: "50%", top: "-2rem" }}>
            <Themesong theme={"light"} />
          </div>
        </div>
        <div className={classes.flexContainerRowTitle}>
          Please select application:
        </div>{" "}
        <div className={classes.flexContainerRow}>
          <div className={classes.leftSide}>
            <div className={classes.previewContainer}>
              <div className={classes.navigationBtn} onClick={dpssHandler}>
                <div className={classes.btnText}>{"To DPSS Test Platform"}</div>
              </div>
              <img src={dpssPrev} alt="" onClick={dpssHandler} />
            </div>
          </div>
          <div className={classes.rightSide}>
            <div className={classes.previewContainer}>
              <div className={classes.navigationBtn} onClick={backendHandler}>
                <div className={classes.btnText}>
                  {"To Backend Data Analyse"}
                </div>
              </div>
              <img src={backendPrev} alt="" onClick={backendHandler} />
            </div>
          </div>
        </div>
        <div className={classes.backendToolsContainer}>
          <div className={classes.backendToolsTitle}>Extra Tools</div>

          <div className={classes.backendToolsSection}>
            {/* VDS CHeck TOOL */}
            <div className={classes.backendToolsTool} onClick={vdsCheckHandler}>
              <div className={classes.backendToolsToolThumbnail}>
                {" "}
                <img src={vdsCheckIcon} alt="" />
              </div>
              <div className={classes.backendToolsToolTitleContainer}>
                <div className={classes.backendToolsToolTitle}>
                  Vehicle Registration Discovery
                </div>
              </div>
            </div>
            {/* Search VIN by UUID TOOL*/}
            <div
              className={classes.backendToolsTool}
              onClick={searchVinByUuidHandler}
            >
              <div className={classes.backendToolsToolThumbnail}>
                <img src={vinSearch} alt="" />
              </div>
              <div className={classes.backendToolsToolTitleContainer}>
                <div className={classes.backendToolsToolTitle}>
                  VIN Transformer
                </div>
              </div>
            </div>
            {/* Pseudonymizer TOOL */}
            <div
              className={classes.backendToolsTool}
              onClick={pseudonymizerHandler}
            >
              <div className={classes.backendToolsToolThumbnail}>
                {" "}
                <img src={pseudonymizer} alt="" />
              </div>
              <div className={classes.backendToolsToolTitleContainer}>
                <div className={classes.backendToolsToolTitle}>
                  Pseudonymizer
                </div>
              </div>
            </div>

            {/* CCP Generator TOOL */}
            <div
              className={classes.backendToolsTool}
              onClick={ccpGeneratorHandler}
            >
              <div className={classes.backendToolsToolThumbnail}>
                <img src={fileGenerator} alt="" />
              </div>
              <div className={classes.backendToolsToolTitleContainer}>
                <div className={classes.backendToolsToolTitle}>
                  CCP File Generator
                </div>
              </div>
            </div>
            {/* Certificate Bundles */}
            <div
              className={classes.backendToolsTool}
              onClick={certificateBundlesHandler}
            >
              <div className={classes.backendToolsToolThumbnail}>
                <img src={certificateBundles} alt="" />
              </div>
              <div className={classes.backendToolsToolTitleContainer}>
                <div className={classes.backendToolsToolTitle}>
                  Certificate Bundles
                </div>
              </div>
            </div>
            {/* Admin Tool Trace Transformer */}
            <div
              className={classes.backendToolsToolTransformers}
              onClick={adminToolTraceTransformerHandler}
            >
              <div className={classes.backendToolsToolThumbnailTransformers}>
                <img src={transformers} alt="" />
              </div>
              <div className={classes.backendToolsToolTitleContainer}>
                <div className={classes.backendToolsToolTitle}>
                  AdminTool TraceTransformer
                </div>
              </div>
            </div>

            {/* Certificate Creator TOOL */}
            <div className={classes.backendToolsTool}>
              {/* <div className={classes.backendToolsToolThumbnail}>
                {" "}
                <img src={certificateCreator} alt="" />
              </div>
              <div className={classes.backendToolsToolTitleContainer}>
                <div className={classes.backendToolsToolTitle}>
                  VKMS Certificate Creator
                </div>
              </div> */}
            </div>
            {/* SOP Viewer TOOL */}
            <div
              className={classes.backendToolsTool}
              // onClick={sopViewerHandler}
            >
              {/* <div className={classes.backendToolsToolThumbnail}>
                {" "}
                <img src={certificateCreator} alt="" />
              </div>
              <div className={classes.backendToolsToolTitleContainer}>
                <div className={classes.backendToolsToolTitle}>SOP Viewer</div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          left: "12.5%",
          bottom: "0.75rem",
          width: "75%",
          textAlign: "center",
          color: "var(--light-gray)",
        }}
      >
        <Typography fontFamily={"var(--thin)"} fontSize={"0.75rem"}>
          This code is protected by intellectual property rights. Dr. Ing. h.c.
          F. Porsche AG owns exclusive rights of use. © 2024, Dr. Ing. h.c. F.
          Porsche AG.
        </Typography>
      </div>
    </>
  );
}

export default LandingPageWithThumbnails;
